import { ContactFormState } from '../store/form';
import { SubmissionError } from 'redux-form';

const getSelectedIntents = (intents: any) =>
  Object.keys(intents)
    .filter(s => !!intents[s]) // only truthy values
    .filter(s => s !== 'testdriveData' && s !== 'swapcarData'); // disregard associated data

const validateEmail = (email: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

export const submitValidation = (formData: ContactFormState) => {
  if (!formData.intents || getSelectedIntents(formData.intents).length === 0) {
    throw new SubmissionError({
      _error:
        'Vælg hvad du gerne vil kontakte forhandleren vedrørende eller skriv en besked.'
    });
  }
};

export const validate = (values: ContactFormState) => {
  const errors: any = {};

  if (!values.user) {
    errors['_error'] = 'Du skal udfylde dine kontaktinformationer';
  } else {
    errors.user = validateContactDetails(values);
  }

  if (values.intents) {
    errors.intents = {};
    const { intents } = values;

    if (intents.swapcar) {
      errors.intents.swapcarData = validateSwapcar(values);
    }

    if (intents.testdrive) {
      errors.intents.testdriveData = validateTestdrive(values);
    }
  }

  return errors;
};

const validateSwapcar = (values: ContactFormState) => {
  const errors: any = {};

  const { swapcarData } = values.intents;

  if (!swapcarData || !swapcarData.licenseplate) {
    errors.licenseplate = 'Nummerpladen skal udfyldes';
  }

  if (!swapcarData || !swapcarData.mileage) {
    errors.mileage = 'Kørte kilometer skal udfyldes';
  }

  if (swapcarData && swapcarData.mileage) {
    const mileage = swapcarData.mileage
      .trim()
      .replace('.', '')
      .replace(',', '');

    if (isNaN(mileage) || parseInt(mileage, 10) < 0) {
      errors.mileage = 'Kørte kilometer skal angives som et positivt tal';
    }
  }

  return errors;
};

const validateTestdrive = (values: ContactFormState) => {
  const errors: any = {};

  const { testdriveData } = values.intents;

  if (!testdriveData || !testdriveData.date) {
    errors.date = 'Du skal vælge en dato';
  }

  if (!testdriveData || !testdriveData.time) {
    errors.time = 'Du skal vælge et tidspunkt';
  }

  return errors;
};

const validateContactDetails = (values: ContactFormState) => {
  const errors: any = {};

  const { firstName, email, phoneNumber } = values.user!;

  if (!firstName) {
    errors.firstName = 'Fornavn er påkrævet';
  }

  if (!email) {
    errors.email = 'E-mail adresse er påkrævet';
  }

  if (email && !validateEmail(email)) {
    errors.email = 'E-mail adressen er ikke valid.';
  }

  if (
    phoneNumber &&
    !/[0-9\s()+.#]{8,20}/.test(phoneNumber.trim().replace(/\s/, ''))
  ) {
    errors.phoneNumber =
      'Dit telefonnummer skal være mellem 8 or 20 karakterer, og må kun bestå af tal, (, ), ., + og #';
  }

  if (values.intents && values.intents.callme && !phoneNumber) {
    errors.phoneNumber =
      'Du skal indtaste telefonnummer, når du vil ringes op.';
  }

  return errors;
};
