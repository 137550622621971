import React from 'react';
import { usePlatform } from './Platform';

export const GDPRText: React.FC = () => {
  const { isDBA } = usePlatform();

  const policyUrl = isDBA
    ? 'https://info.dba.dk/vilkaar/v5/persondatapolitik'
    : 'https://www.bilbasen.dk/persondatapolitik';

  return (
    <div className='gdpr-text'>
      Når du trykker på "Send", accepterer du, at vi overfører dine
      personoplysninger til sælger af bilen. Læs om behandlingen af dine
      personoplysninger i vores{' '}
      <a href={policyUrl} target='_blank' rel='noopener noreferrer'>
        persondatapolitik
      </a>
      .
    </div>
  );
};
