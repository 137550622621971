import React, { useEffect } from 'react';
import './Recaptcha.css';

declare global {
  interface Window {
    grecaptcha?: {
      execute: () => void;
      getResponse: () => string;
    };
    captchaOnload: () => void;
    captchaCallback: (token: string) => void;
  }
}

interface IProps {
  siteKey: string;
  onLoad?: () => void;
  onComplete: (token: string) => void;
  invisible?: boolean;
}

const loadCaptchaScript = () => {
  if (window.grecaptcha) {
    return;
  }

  const script = document.createElement('script');
  script.src = 'https://www.google.com/recaptcha/api.js?onload=captchaOnload';
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
};

export const Recaptcha: React.FC<IProps> = ({
  siteKey,
  onLoad,
  onComplete,
  invisible = true
}) => {
  useEffect(() => {
    // Register callbacks
    window.captchaOnload = () => {
      (window as any).innerWidth = 512; // Safari 12 iframe hack - see https://github.com/google/recaptcha/issues/312
      onLoad && onLoad();
      invisible && window.grecaptcha!.execute();
    };

    window.captchaCallback = (token: string) => {
      onComplete && onComplete(token);
    };

    // Load Google Captcha script
    loadCaptchaScript();
  }, [onLoad, onComplete, invisible]);

  const token = window.grecaptcha ? window.grecaptcha.getResponse() : '';
  if (token.length > 0) {
    onComplete(token);
  }

  return (
    <>
      <div
        id='g-recaptcha'
        className='g-recaptcha'
        data-sitekey={siteKey}
        data-size={invisible ? 'invisible' : 'compact'}
        data-badge='inline'
        data-callback='captchaCallback'
      ></div>
      {invisible && (
        <div className='recaptcha-consent'>
          This site is protected by reCAPTCHA and the Google{' '}
          <a href='https://policies.google.com/privacy'>Privacy Policy</a> and{' '}
          <a href='https://policies.google.com/terms'>Terms of Service</a>{' '}
          apply.
        </div>
      )}
    </>
  );
};
