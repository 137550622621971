import React from 'react';
import {
  FormSection,
  Field,
  formValueSelector,
  WrappedFieldProps
} from 'redux-form';
import {
  getDateSlots,
  getTimeSlots,
  getDateString
} from '../utils/openingHours';
import { RootState } from 'typesafe-actions';
import { connect } from 'react-redux';
import { name as formName } from '../store/form';

/**
 * Calculate opening hours for all days
 *
 * Get current day
 * Get opening hours for current day
 * If current time is less than an hour before closing time, allow current date
 *
 * If current date is picked, filter away all options in the past and within the next 30 minutes
 * If not, show all available times slots for the picked day
 */

interface IProps {
  selectedDate: number;
  openingHours: Reply.OpeningHours;
}

const SelectWithError: React.FC<WrappedFieldProps> = ({
  input,
  meta,
  children,
  ...rest
}) => (
  <>
    <select {...input} {...rest}>
      {children}
    </select>
    {meta.touched && meta.error && <p className='error'>{meta.error}</p>}
  </>
);

const TestdriveRequestFormView: React.FC<IProps> = ({ selectedDate, openingHours }) => (
  <FormSection name='testdriveData' id='TestdriveRequestForm'>
    <p>Hvilket tidsrum passer dig bedst?</p>
    <div>
      <Field
        name='date'
        component={SelectWithError}
        normalize={(value: string) => parseInt(value, 10)}
      >
        <option disabled={true} value=''>
          Vælg dato
        </option>
        {getDateSlots(openingHours).map(date => (
          <option key={date.getTime()} value={date.getTime()}>
            {getDateString(date)}
          </option>
        ))}
      </Field>
      {/* Lock time input if no date is selected */}
      <Field name='time' component={SelectWithError} disabled={!selectedDate}>
        <option value=''>Vælg tid</option>
        {selectedDate &&
          getTimeSlots(new Date(selectedDate), openingHours).map(timeslot => (
            <option key={timeslot} value={timeslot}>
              {timeslot}
            </option>
          ))}
      </Field>
    </div>
    <p className='intent-disclaimer'>
      <strong>Bemærk:</strong> Dette er kun en forespørgsel. <br />
      Forhandleren tager kontakt til dig snarest.
    </p>
  </FormSection>
);

const selector = formValueSelector(formName);

const mapStateToProps = (state: RootState) => ({
  selectedDate: selector(state, 'intents.testdriveData.date'),
  openingHours: state.hostData.carData.openingHours
});

const mapDispatchToProps = () => ({});

export const TestdriveRequestForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(TestdriveRequestFormView);
