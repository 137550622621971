import React from 'react';
import { WrappedFieldProps } from 'redux-form';

export const TextfieldWithError: React.FC<WrappedFieldProps> = ({
  input,
  meta,
  ...rest
}) => (
  <>
    <input type='text' id={input.name} {...input} {...rest} />
    {meta.touched && meta.error && <p className='error'>{meta.error}</p>}
  </>
);
