import React from 'react';

export const SubmittingSpinner: React.FC = () => (
  <div className='overlay'>
    <div className='overlay-content spinner'>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);
