import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootAction, RootState } from 'typesafe-actions';
import { setHostData } from './store/hostData';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Form } from './components/Form';
import { CannedForm } from './components/CannedForm';
import { ListingPreview } from './components/ListingPreview';
import { ContactFormState } from './store/form';
import { mapToCloseMessage, mapToSubmitData } from './data/mapper';
import { SubmittingSpinner } from './components/SubmittingSpinner';
import { submitValidation } from './data/validation';
import { submit } from './services/api';
import { ErrorOverlay } from './components/ErrorOverlay';
import { isAndroid } from './utils/platform';
import { PlatformProvider } from './components/Platform';
import { TrackingProvider, TrackingData } from './components/Tracking';
import { cannedMessages } from './components/CannedMessages';

interface IProps {
  data: Reply.HostData;
  isSubmitting: boolean;
  captchaToken: string;
  onSubmitted: (data: Reply.CloseMessageData) => void;
  onCancel: (data: Reply.CancelMessageData) => void;
  onTrack: (data: Reply.TrackMessageData) => void;
  onDataChange: (data: Reply.HostData) => void;
}

const App: React.FC<IProps> = ({
  isSubmitting,
  onSubmitted,
  onCancel,
  data,
  onDataChange,
  onTrack,
  captchaToken,
}) => {
  useEffect(() => {
    // Set initial data for form via Redux action
    onDataChange(data);
  }, [data, onDataChange]);
  const [error, setError] = useState<undefined | Error>(undefined);

  const handleSubmit = (formData: ContactFormState) => {
    setError(undefined);
    submitValidation(formData);

    trackCannedMessages(formData.intents.message);

    const sleepPromise = new Promise<void>((resolve) =>
      setTimeout(() => {
        resolve();
      }, 1000)
    );

    const dataToSubmit = mapToSubmitData(data, formData, captchaToken);
    const submitPromise = submit(dataToSubmit);

    // Need to return the form data after completing the promises, so that it is passed to onSubmitSuccess
    return Promise.all([submitPromise, sleepPromise]).then(() => formData);
  };

  const handleError = (errors: any, _dispatch: unknown, submitError: Error) => {
    // Only display full error modal, if we have connection errors - validation errors will be displayed in form

    if (!!submitError && typeof errors === 'undefined') {
      handleTrack({
        category: 'ListingReply',
        action: 'Error',
        label: submitError.message,
      });
      setError(submitError);
    }
  };

  const handleSuccess = (values: ContactFormState) => {
    const dataForPlatform = mapToCloseMessage(data, values);
    onSubmitted(dataForPlatform);
  };

  const handleCancel = () => {
    onCancel({ action: 'cancel' });
  };

  const handleCloseError = () => {
    setError(undefined);
  };

  const handleTrack = (trackingData: TrackingData) => {
    onTrack({ action: 'track', data: trackingData });
  };

  const trackCannedMessages = (message: string | undefined) => {
    if (!message || message.length <= 10) {
      return;
    }

    const used = cannedMessages
      .filter((cm) => message.indexOf(cm.text) >= 0)
      .map((cm) => cm.id)
      .join(', ');

    handleTrack({
      category: 'ListingReply',
      action: 'RepliesCannedMessageSubmit',
      label: used,
    });
  };

  return (
    <PlatformProvider
      platform={data.carData.source.toLowerCase()}
      isApp={data.host === 'app'}
    >
      <TrackingProvider track={handleTrack}>
        <div
          id='reply'
          className={
            data.carData.source.toLowerCase() === 'bilbasen'
              ? 'bilbasen-styles'
              : 'dba-styles'
          }
        >
          <Header sellerName={data.seller.name} onCancel={handleCancel} />
          {isAndroid() && <ListingPreview car={data.carData} />}
          {data.variant && data.variant === 'Canned' ? (
            <CannedForm
              onSubmit={handleSubmit}
              onSubmitSuccess={handleSuccess}
              onSubmitFail={handleError}
              showCaptcha={data.host !== 'app'} // showing captcha on apps results in drop in leads
            />
          ) : (
            <Form
              onSubmit={handleSubmit}
              onSubmitSuccess={handleSuccess}
              onSubmitFail={handleError}
              showCaptcha={data.host !== 'app'} // showing captcha on apps results in drop in leads
            />
          )}
          <Footer />
          {isSubmitting && <SubmittingSpinner />}
          {error && <ErrorOverlay onCancel={handleCloseError} error={error} />}
        </div>
      </TrackingProvider>
    </PlatformProvider>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSubmitting: state.form.contact ? !!state.form.contact.submitting : false,
  captchaToken: state.captcha.completed ? state.captcha.token : '',
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onDataChange: (data: Reply.HostData) => dispatch(setHostData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
