import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './store';
import TestData from './data/testdata.json';
import 'iframe-resizer'; // Let's the iframe resize itself to content

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const postMessage = (
  message:
    | Reply.CloseMessageData
    | Reply.CancelMessageData
    | Reply.TrackMessageData
) => window.parent.postMessage(message, '*');

const renderApp = (data: Reply.HostData) =>
  ReactDOM.render(
    <Provider store={store}>
      <App
        data={data}
        onSubmitted={postMessage}
        onCancel={postMessage}
        onTrack={postMessage}
      />
    </Provider>,
    document.getElementById('root')
  );

/**
 * Determine if we are loaded within an iframe on Bilbasen or DBA
 *  - otherwise we are testing or being accessed directly on listingreply.ecgh.dk
 */
var isEmbedded = window !== window.parent;

const isOriginOK = (origin: string) =>
  origin.indexOf('http://localhost') >= 0 ||
  origin.indexOf('.dba.dk') >= 0 ||
  origin.indexOf('.bilbasen.dk') >= 0;

if (isEmbedded) {
  window.addEventListener(
    'message',
    message => {
      if (isOriginOK(message.origin) && message.data && message.data.carData) {
        renderApp(message.data);
      }
    },
    false
  );
} else {
  renderApp(TestData);
}
