import React, { Dispatch } from 'react';
import {
  reduxForm,
  InjectedFormProps,
  Field,
  FormSection,
  clearSubmitErrors
} from 'redux-form';
import { connect } from 'react-redux';
import { TextfieldWithLabel } from './TextfieldWithLabel';
import { RootState, RootAction } from 'typesafe-actions';
import { captchaComplete } from '../store/captcha';
import { Recaptcha } from './Recaptcha';
import { ContactFormState, name as formName } from '../store/form';
import { validate } from '../data/validation';
import { isQA } from '../utils/platform';
import { CannedMessages } from './CannedMessages';

interface IProps {
  onCaptchaComplete: (token: string) => void;
  showCaptcha: boolean;
}

export const CannedFormView: React.FC<IProps &
  InjectedFormProps<ContactFormState, IProps>> = ({
  onCaptchaComplete,
  error,
  showCaptcha = true
}) => {
  if (!showCaptcha) {
    onCaptchaComplete('NO_CAPTCHA_SHOWN');
  }

  return (
    <form>
      <FormSection name='user' className='left-area cannedmessage-left-area'>
        <h4>Min kontaktinformation</h4>

        <Field
          name='firstName'
          label='Fornavn'
          component={TextfieldWithLabel}
        />
        <Field
          name='lastName'
          label='Efternavn'
          component={TextfieldWithLabel}
        />
        <Field
          name='email'
          label='E-mail'
          component={TextfieldWithLabel}
          type='email'
        />
        <Field
          name='phoneNumber'
          label='Telefonnummer'
          component={TextfieldWithLabel}
          pattern='[0-9\s\(\)\+\.#]+'
          minLength={8}
          maxLength={20}
        />

        {showCaptcha && (
          <Recaptcha
            siteKey={
              isQA()
                ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' // Test sitekey that will always pass captcha on the frontend - in PROD we will check on the backend, and if a bot somehow uses this sitekey, they will fail
                : (process.env.REACT_APP_CAPTCHA_SITEKEY as string)
            }
            onComplete={onCaptchaComplete}
            invisible={false}
          />
        )}
      </FormSection>

      <FormSection
        name='intents'
        className='right-area cannedmessage-right-area'
      >
        <CannedMessages />
        <Field
          name='message'
          component='textarea'
          placeholder='Skriv din besked her'
          rows={5}
        ></Field>
        {error && <p className='error'>{error}</p>}
      </FormSection>
    </form>
  );
};

const ReduxedForm = reduxForm<ContactFormState, IProps>({
  form: formName,
  enableReinitialize: true,
  validate: validate,
  onChange: (_, dispatch, props) =>
    !!props.error && dispatch(clearSubmitErrors(formName)) // clearing submit error manually, as change events seem to not clear submit errors (maybe because the error is general and not associated with a field)
})(CannedFormView);

const createInitialMessage = (state: RootState) => {
  let message = '';

  if (state.hostData.userRequestedATestDrive) {
    message += 'Kan jeg prøvekøre bilen?\r\n';
  }

  if (
    state.hostData.financeDetails &&
    state.hostData.financeDetails.financeOffer
  ) {
    message += 'Kan I hjælpe med et finansieringstilbud?\r\n';
  }

  return message;
};

const mapStateToProps = (state: RootState) => ({
  initialValues: {
    user: state.hostData.defaultUserInfo,
    intents: {
      message: createInitialMessage(state)
    }
  }
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onCaptchaComplete: (token: string) => dispatch(captchaComplete(token))
});

export const CannedForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReduxedForm);
