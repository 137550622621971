import { createStore, applyMiddleware } from 'redux';
import { reducer as rootReducer } from './root-reducer';
import { composeEnhancers } from './utils';

// compose enhancers
const enhancers = composeEnhancers(applyMiddleware());

const initialState = {};

export const store = createStore(rootReducer, initialState, enhancers);
