import { createStandardAction, createReducer } from 'typesafe-actions';

export const setHostData = createStandardAction('SET_HOST_DATA')<
  Reply.HostData
>();

const initialState: Reply.HostData = {
  source: 'direct',
  defaultUserInfo: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: ''
  },
  seller: {
    name: '',
    encryptedSellerEmail: '',
    sellerId: ''
  },
  carData: {
    listingUrl: '',
    mileage: 0,
    price: 0,
    source: '',
    variant: '',
    vehicleMake: '',
    vehicleModel: '',
    vehicleYear: '',
    image: '',
    syiId: '',
    globalId: '',
    adSourceTenant: '',
    openingHours: {
      mondayOpen: "0001-01-01T00:00:00",
      mondayClose: "0001-01-01T00:00:00",
      tuesdayOpen: "0001-01-01T00:00:00",
      tuesdayClose: "0001-01-01T00:00:00",
      wednesdayOpen: "0001-01-01T00:00:00",
      wednesdayClose: "0001-01-01T00:00:00",
      thursdayOpen: "0001-01-01T00:00:00",
      thursdayClose: "0001-01-01T00:00:00",
      fridayOpen: "0001-01-01T00:00:000",
      fridayClose: "0001-01-01T00:00:00",
      saturdayOpen: "0001-01-01T00:00:00",
      saturdayClose: "0001-01-01T00:00:00",
      sundayOpen: "0001-01-01T00:00:00",
      sundayClose: "0001-01-01T00:00:00",
      mondayAppointmentOnly: false,
      tuesdayAppointmentOnly: false,
      wednesdayAppointmentOnly: false,
      thursdayAppointmentOnly: false, 
      fridayAppointmentOnly: false,
      saturdayAppointmentOnly: false,
      sundayAppointmentOnly: false
    }
  }
};

export const reducer = createReducer(initialState).handleAction(
  setHostData,
  (_, action) => action.payload
);
