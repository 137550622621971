import { actionTypes } from 'redux-form';
import { Reducer } from 'redux';
import { name } from './index';

/**
 * Plugin to reset the testdrive time selection dropdown, when the date is changed (and new time options are presented)
 */
export const contactPlugin: Reducer<any> = (state, action) => {
  switch (action.type) {
    case actionTypes.CHANGE: {
      if (
        action.meta.form === name &&
        action.meta.field === 'intents.testdriveData.date'
      ) {
        return {
          ...state,
          values: {
            ...state.values,
            intents: {
              ...state.values.intents,
              testdriveData: state.values.intents.testdriveData
                ? { ...state.values.intents.testdriveData, time: undefined }
                : undefined
            }
          }
        };
      }
      return state;
    }
    default:
      return state;
  }
};
