import React from 'react';

interface IProps {
  track: TrackingFunction;
}

export interface TrackingData {
  category: string;
  action: string;
  label?: string;
}

type TrackingFunction = (trackingData: TrackingData) => void;

const TrackingContext = React.createContext<TrackingFunction | undefined>(
  undefined
);

export const TrackingProvider: React.FC<IProps> = ({ children, track }) => (
  <TrackingContext.Provider value={track}>{children}</TrackingContext.Provider>
);

export const useTracking = () => {
  const context = React.useContext(TrackingContext);
  if (context === undefined) {
    throw new Error('useTracking must be used within a TrackingProvider');
  }
  return context;
};
