const userAgent = window.navigator.userAgent;

type OS = 'ios' | 'android' | 'other';

export const getOS = (): OS => {
  if (/iPhone|iPad/i.test(userAgent)) {
    return 'ios';
  }

  if (/Android/i.test(userAgent)) {
    return 'android';
  }

  return 'other';
};

export const isAndroid = () => getOS() === 'android';

export const isIOS = () => getOS() === 'ios';

export const isQA = () =>
  document.location.host.toUpperCase().indexOf('QA') >= 0;
