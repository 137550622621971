import React from 'react';

interface IProps {
  sellerName?: string;
  onCancel: () => void;
}

export const Header: React.FC<IProps> = ({ sellerName, onCancel }) => {
  const isShownOnScaledVIP =
    window.parent?.location !== window.parent?.parent?.location; // the new scaled BB VIP wraps/embeds the whole Lead Qual in an extra iframe, amounting to 2 iframes to display Lead Qual...
  return (
    <header>
      <h2>
        {sellerName ? `Skriv til ${sellerName}` : 'Skriv besked'}
        {!isShownOnScaledVIP && (
          <div className='close' onClick={onCancel}>
            &times;
          </div>
        )}
      </h2>
    </header>
  );
};
