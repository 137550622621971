import React from 'react';
import { ActionType } from 'typesafe-actions';
import { submit } from 'redux-form';
import { connect } from 'react-redux';
import { name as formName } from '../store/form';
import { Dispatch } from 'redux';

interface IProps {
  onRetry: () => void;
  onCancel: () => void;
  error?: Error;
}

const ErrorOverlayView: React.FC<IProps> = ({ onRetry, onCancel, error }) => {
  console.log(error);

  return (
    <div className='overlay'>
      <div className='overlay-content error-overlay'>
        <h3>Øv, der opstod et problem...</h3>
        <p style={{ marginBottom: 12 }}>Vi kunne ikke sende din besked.</p>
        <div>
          <button onClick={onCancel} className='btn-secondary'>
            Luk
          </button>
          <button onClick={onRetry} className='btn-primary'>
            Prøv igen
          </button>
        </div>
        {error && <div className='error-message'>{error.message}</div>}
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch<ActionType<typeof submit>>) => ({
  onRetry: () => dispatch(submit(formName))
});

export const ErrorOverlay = connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorOverlayView);
