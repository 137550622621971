import React from 'react';
import { RootState, ActionType } from 'typesafe-actions';
import { change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { name as formName } from '../store/form';
import { useTracking } from './Tracking';

type CannedMessage = {
  id: string;
  text: string;
};

export const cannedMessages: CannedMessage[] = [
  { id: 'SwapCar', text: 'Tager I bil i bytte?' },
  { id: 'Testdrive', text: 'Kan jeg prøvekøre bilen?' },
  { id: 'NegotiablePrice', text: 'Er prisen til forhandling?' },
  { id: 'CallMe', text: 'Kan I ringe mig op?' },
  { id: 'FinanceOffer', text: 'Kan I hjælpe med et finansieringstilbud?' }
];

const selector = formValueSelector(formName);

const CannedMessage: React.FC<{
  text: string;
  selected: boolean;
  onClick: () => void;
}> = ({ text, selected, onClick }) => {
  return (
    <button
      type='button'
      className={`canned-message ${selected ? 'selected' : ''}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

interface IProps {
  message?: string;
  setMessage: (text: string) => void;
}

export const CannedMessagesView: React.FC<IProps> = ({
  message,
  setMessage
}) => {
  const track = useTracking();

  const addToMessage = (cm: CannedMessage) => () => {
    if (message && message.indexOf(cm.text) >= 0) {
      setMessage(message.replace(`\r\n${cm.text}`, '').replace(cm.text, ''));
      track({
        category: 'ListingReply',
        action: 'RepliesCannedMessageRemoved',
        label: cm.id
      });
    } else {
      setMessage(`${message || ''}\r\n${cm.text}`.trim());
      track({
        category: 'ListingReply',
        action: 'RepliesCannedMessageAdded',
        label: cm.id
      });
    }
  };

  const messageContains = (text: string) =>
    !!message && message.indexOf(text) >= 0;

  return (
    <div id='canned-messages'>
      <h4>Start samtalen med et spørgsmål</h4>
      {cannedMessages.map(cm => (
        <CannedMessage
          key={cm.id}
          text={cm.text}
          onClick={addToMessage(cm)}
          selected={messageContains(cm.text)}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  message: selector(state, 'intents.message')
});

const mapDispatchToProps = (dispatch: Dispatch<ActionType<typeof change>>) => ({
  setMessage: (text: string) =>
    dispatch(change(formName, 'intents.message', text))
});

export const CannedMessages = connect(
  mapStateToProps,
  mapDispatchToProps
)(CannedMessagesView);
