import React from 'react';
import { FormSection, Field } from 'redux-form';
import { TextfieldWithError } from './TextfieldWithError';

export const SwapCarForm: React.FC = () => (
  <FormSection name='swapcarData' id='SwapCarRequestForm'>
    <Field
      name='licenseplate'
      placeholder='Nummerplade'
      maxLength={10}
      component={TextfieldWithError}
      normalize={(val: string) => val.toUpperCase()}
      required
    />
    <Field
      name='mileage'
      placeholder='Kørte kilometer'
      maxLength={12}
      component={TextfieldWithError}
      required
    />
  </FormSection>
);
