import { createStandardAction, createReducer } from 'typesafe-actions';

export const captchaComplete = createStandardAction('CAPTCHA_COMPLETE')<
  string
>();

const initialState = {
  completed: false,
  token: ''
};

export const reducer = createReducer(initialState).handleAction(
  captchaComplete,
  (_, action) => ({ completed: true, token: action.payload })
);
