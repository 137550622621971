import React from 'react';
import { isAndroid, isIOS, isQA } from '../utils/platform';

interface IPlatform {
  isAndroid: boolean;
  isIOS: boolean;
  isQA: boolean;
  isDBA: boolean;
  isBilbasen: boolean;
  isApp: boolean;
  platform: Platform;
}

interface IProps {
  platform: string;
  isApp: boolean;
}

const PlatformContext = React.createContext<IPlatform | undefined>(undefined);

export const PlatformProvider: React.FC<IProps> = ({
  children,
  platform,
  isApp
}) => {
  const platformTools = {
    platform: platform as Platform,
    isAndroid: isAndroid(),
    isIOS: isIOS(),
    isQA: isQA(),
    isDBA: platform === 'dba',
    isBilbasen: platform === 'bilbasen',
    isApp
  };

  return (
    <PlatformContext.Provider value={platformTools}>
      {children}
    </PlatformContext.Provider>
  );
};

export const usePlatform = () => {
  const context = React.useContext(PlatformContext);
  if (context === undefined) {
    throw new Error('usePlatform must be used within a PlatformProvider');
  }
  return context;
};
