import { reducer as formReducer } from 'redux-form';
import { contactPlugin } from './plugin';

export const name = 'contact';

export const reducer = formReducer.plugin({
  contact: contactPlugin
});

export interface ContactFormState {
  user?: Reply.UserData;
  intents?: any;
}
