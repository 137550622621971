import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { submit } from 'redux-form';
import { ActionType, RootState } from 'typesafe-actions';
import { GDPRText } from './GDPRText';
import { name as formName } from '../store/form';

interface IProps {
  onSubmit: () => void;
  disableSend: boolean;
  sendButtonText: string;
}

export const FooterView: React.FC<IProps> = ({
  onSubmit,
  disableSend,
  sendButtonText
}) => {
  return (
    <footer>
      <div className='left-area'>
        <GDPRText />
      </div>
      <div className='right-area'>
        <button
          onClick={onSubmit}
          className='btn-primary'
          disabled={disableSend}
          style={{ width: '100%' }}
        >
          {sendButtonText}
        </button>
      </div>
    </footer>
  );
};

const mapStateToProps = (state: RootState) => ({
  disableSend: !state.captcha.completed || !!state.form.contact.submitting,
  sendButtonText: state.captcha.completed ? 'Send' : 'Afventer captcha...'
});

const mapDispatchToProps = (dispatch: Dispatch<ActionType<typeof submit>>) => ({
  onSubmit: () => dispatch(submit(formName))
});

export const Footer = connect(mapStateToProps, mapDispatchToProps)(FooterView);
