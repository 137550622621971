import { reducer as captchaReducer } from './captcha';
import { reducer as hostDataReducer } from './hostData';
import { reducer as formReducer } from './form';
import { combineReducers } from 'redux';

export const reducer = combineReducers({
  form: formReducer,
  captcha: captchaReducer,
  hostData: hostDataReducer
});
