import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { TextfieldWithError } from './TextfieldWithError';

interface IProps extends WrappedFieldProps {
  label: string;
}

export const TextfieldWithLabel: React.FC<IProps> = ({
  input,
  label,
  ...rest
}) => (
  <>
    <label htmlFor={input.name}>{label}</label>
    <TextfieldWithError input={input} {...rest} />
  </>
);
