/**
 * Date.toLocaleString() is not supported in Android WebView, so we have to do this conversion manually
 */

const AVAILABLE_FUTURE_DAYS_TO_BOOK = 14;

type OpeningHour = { open: number; close: number };

const dayName = [
  'Søndag',
  'Mandag',
  'Tirsdag',
  'Onsdag',
  'Torsdag',
  'Fredag',
  'Lørdag',
];

const monthName = [
  'januar',
  'februar',
  'marts',
  'april',
  'maj',
  'juni',
  'juli',
  'august',
  'september',
  'oktober',
  'november',
  'december',
];

const defaultOpeningHours = [
  { open: 9, close: 17 }, // Sunday
  { open: 9, close: 17 }, // Monday
  { open: 9, close: 17 }, // Tuesday
  { open: 9, close: 17 }, // Wednesday
  { open: 9, close: 17 }, // Thursday
  { open: 9, close: 17 }, // Friday
  { open: 9, close: 17 }, // Saturday
];

const isOpenOnDate = (openingHours: OpeningHour[], date: Date) =>
  openingHours[date.getDay()].close - openingHours[date.getDay()].open > 0;

const isOpen = (openingHours: OpeningHour[], date: Date) => {
  if (isOpenOnDate(openingHours, date)) {
    const closeMinutes = openingHours[date.getDay()]!.close * 60;
    const nowMinutes = date.getHours() * 60 + date.getMinutes();

    return nowMinutes < closeMinutes;
  } else {
    return false;
  }
};

const parseOpeningHours = (openingHours: Reply.OpeningHours): OpeningHour[] => {
  if (openingHours) {
    if (
      openingHours.sundayAppointmentOnly &&
      openingHours.mondayAppointmentOnly &&
      openingHours.tuesdayAppointmentOnly &&
      openingHours.wednesdayAppointmentOnly &&
      openingHours.thursdayAppointmentOnly &&
      openingHours.fridayAppointmentOnly &&
      openingHours.saturdayAppointmentOnly
    ) {
      return defaultOpeningHours;
    }

    let parsedOpeningHours = [
      {
        open: new Date(openingHours.sundayOpen).getHours(),
        close: new Date(openingHours.sundayClose).getHours(),
      },
      {
        open: new Date(openingHours.mondayOpen).getHours(),
        close: new Date(openingHours.mondayClose).getHours(),
      },
      {
        open: new Date(openingHours.tuesdayOpen).getHours(),
        close: new Date(openingHours.tuesdayClose).getHours(),
      },
      {
        open: new Date(openingHours.wednesdayOpen).getHours(),
        close: new Date(openingHours.wednesdayClose).getHours(),
      },
      {
        open: new Date(openingHours.thursdayOpen).getHours(),
        close: new Date(openingHours.thursdayClose).getHours(),
      },
      {
        open: new Date(openingHours.fridayOpen).getHours(),
        close: new Date(openingHours.fridayClose).getHours(),
      },
      {
        open: new Date(openingHours.saturdayOpen).getHours(),
        close: new Date(openingHours.saturdayClose).getHours(),
      },
    ];

    if (parsedOpeningHours.every((x) => !isNaN(x.open) && !isNaN(x.close))) {
      return parsedOpeningHours;
    }
  }

  return defaultOpeningHours;
};

export const getDateString = (date: Date) =>
  `${dayName[date.getDay()]} d. ${date.getDate()}. ${
    monthName[date.getMonth()]
  }`;

export const getDateSlots = (
  openingHours: Reply.OpeningHours,
  startDate: Date = new Date(Date.now())
) => {
  const dateSlots: Date[] = [];
  const openingHoursParsed = parseOpeningHours(openingHours);

  if (isOpen(openingHoursParsed, startDate)) {
    dateSlots.push(startDate);
  }

  for (
    let offset = 1;
    dateSlots.length < AVAILABLE_FUTURE_DAYS_TO_BOOK;
    offset++
  ) {
    const date = new Date(startDate);
    date.setHours(0, 0, 0, 0);
    date.setDate(startDate.getDate() + offset);

    if (isOpenOnDate(openingHoursParsed, date)) {
      dateSlots.push(date);
    }
  }

  return dateSlots;
};

export const getTimeSlots = (date: Date, openingHours: Reply.OpeningHours) => {
  const timeslots: string[] = [];
  const openingHoursParsed = parseOpeningHours(openingHours);
  const dateOpeningHours = openingHoursParsed[date.getDay()]!;

  // Not likely to happen, as we should only suggest dates that have timeslots
  if (dateOpeningHours === null) {
    return [];
  }

  const next = new Date(date.getTime() + 30 * 60000);

  for (
    let hour = Math.max(dateOpeningHours.open, next.getHours() + 1);
    hour < dateOpeningHours.close;
    hour++
  ) {
    timeslots.push(`kl. ${hour} - ${hour + 1}`);
  }

  return timeslots;
};
