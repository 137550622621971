import { ContactFormState } from '../store/form';
import { getDateString } from '../utils/openingHours';
import { pulseEmailDealerEvent, PulseFormField } from '@smd/tracking';

export const mapToSubmitData = (
  data: Reply.HostData,
  formData: ContactFormState,
  captcha: string
): API.SubmitData => {
  const {
    user,
    intents: {
      message,
      callme,
      financeoffer,
      swapcar,
      swapcarData,
      testdrive,
      testdriveData,
    },
  } = formData;

  return {
    emailData: {
      ...user!,
      askQuestion: false, // No need to indicate that a question is answered - just provide the message
      callme,
      financeOffer: financeoffer,
      includecar: swapcar,
      licenseplate: swapcar ? swapcarData.licenseplate : '',
      mileage: swapcar
        ? swapcarData.mileage.trim().replace('.', '').replace(',', '')
        : 0,
      message: message || '',
      userRequestedATestDrive: testdrive,
      offer: false,
    },
    carData: data.carData,
    financeDetails: {
      financeOffer: financeoffer,
      preselectedFinanceOffer: data.financeDetails
        ? data.financeDetails.preselectedFinanceOffer
        : '',
    },
    testDrive: {
      bookATestDriveDate: testdriveData
        ? getDateString(new Date(testdriveData.date))
        : '',
      bookATestDriveTime: testdriveData ? testdriveData.time : '',
    },
    sellerData: data.seller,
    captcha,
    isInNativeApp: data.host === 'app', // host comes from the host page from monolith
    videoDetails: {
      userRequestedVideo: false,
      generelVideoOfCar: false,
      interior: false,
      engineCompartment: false,
      damages: false,
      other: false,
      otherText: '',
    },
  };
};

export const mapToCloseMessage = (
  data: Reply.HostData,
  formData: ContactFormState
): Reply.CloseMessageData => {
  const {
    user,
    intents: {
      message,
      callme,
      financeoffer,
      swapcar,
      swapcarData,
      testdrive,
      testdriveData,
    },
  } = formData;

  const pulseFormData: Array<PulseFormField> = [
    {
      key: 'askquestion',
      value: (!!(message && message.length > 0)).toString(),
    },
    { key: 'callme', value: (!!callme).toString() },
    { key: 'userRequestedATestDrive', value: (!!testdrive).toString() },
    { key: 'includecar', value: (!!swapcar).toString() },
    { key: 'financeOffer', value: (!!financeoffer).toString() },
    { key: 'source', value: data.source },
  ];

  if (testdrive && testdriveData) {
    pulseFormData.push({
      key: 'bookATestDriveDate',
      value: getDateString(new Date(testdriveData.date)),
    });
    pulseFormData.push({
      key: 'bookATestDriveTime',
      value: testdriveData.time,
    });
  }
  if (swapcar && swapcarData) {
    pulseFormData.push({
      key: 'licenseplate',
      value: swapcarData.licenseplate,
    });
    pulseFormData.push({ key: 'mileage', value: swapcarData.mileage });
  }
  if (message && message.length > 0) {
    pulseFormData.push({ key: 'message', value: message });
  }

  const syiIdSdrn =
    'sdrn:' + data.carData.adSourceTenant + ':classified:' + data.carData.syiId;
  const pulseTrackingEvent = pulseEmailDealerEvent(
    data.carData.globalId?.toString(),
    syiIdSdrn,
    pulseFormData
  );

  return {
    action: 'close',
    source: data.source,
    seller: data.seller.name,
    data: {
      ...user,
      askquestion: message && message.length > 0,
      userRequestedATestDrive: !!testdrive,
      bookATestDriveDate:
        testdrive && testdriveData
          ? getDateString(new Date(testdriveData.date))
          : undefined,
      bookATestDriveTime:
        testdrive && testdriveData ? testdriveData.time : undefined,
      callme,
      financeOffer: financeoffer,
      includecar: swapcar,
      licenseplate:
        swapcar && swapcarData ? swapcarData.licenseplate : undefined,
      mileage: swapcar && swapcarData ? swapcarData.mileage : undefined,
      message,
      pulseTrackingEvent: pulseTrackingEvent,
    },
  };
};
