import React from 'react';
import './ListingPreview.css';

interface IProps {
  car: Reply.CarData;
}

export const ListingPreview: React.FC<IProps> = ({ car }) =>
  car.image && car.image.length > 0 ? (
    <div id='listing-preview'>
      <div className='car-image-container-outer'>
        <div className='car-image-container'>
          <div
            className='car-image'
            style={{
              backgroundImage: `url(${car.image})`
            }}
          ></div>
        </div>
      </div>
      <div className='car-details'>
        <h2 className='text-overflow'>
          {car.vehicleMake} {car.vehicleModel}
        </h2>
        <h3 className='text-overflow'>{car.variant}</h3>
      </div>
    </div>
  ) : null;
