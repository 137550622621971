import React from 'react';
import { Field, WrappedFieldProps } from 'redux-form';

interface IIntentToggleProps extends WrappedFieldProps {
  label: string;
}

const IntentToggle: React.FC<IIntentToggleProps> = ({
  input,
  children,
  label
}) => {
  return (
    <div className='intent-wrapper'>
      <label id={`lbl-${input.name}`}>
        <input type='checkbox' {...input} checked={input.value} />
        {label}
      </label>
      {input.value && children && <div className='intent-form'>{children}</div>}
    </div>
  );
};

interface IProps {
  name: string;
  label: string;
}

export const Intent: React.FC<IProps> = ({ name, children, ...rest }) => (
  <Field name={name} component={IntentToggle} {...rest}>
    {children}
  </Field>
);
