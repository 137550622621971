export const submit = (data: API.SubmitData) =>
  fetch('api/submit', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(data)
  }).then(response => {
    if (!response.ok) {
      throw new Error(
        `Fejlbesked: ${response.status} - ${response.statusText}`
      );
    }
  });
